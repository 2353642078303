import React from 'react';
import { FaClock, FaMapMarkerAlt } from "react-icons/fa";
import { MapContainer, TileLayer, Popup} from 'react-leaflet';
import { Marker } from 'react-leaflet/Marker'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet'

function StoreInfo() {
    const position = [40.768650, -73.647790];
    const customMarkerIcon = L.icon({
        iconUrl: './map-pin.svg',  // Path to SVG icon
        iconSize: [40, 60], // Size of the icon
        iconAnchor: [20, 45], // Point of the icon which will correspond to marker's location
        popupAnchor: [1, -25], // Point from which the popup should open relative to the iconAnchor
    });
    return (
        // Change here: Use sm:flex-row to switch to row on wider screens
        <div className="flex flex-col sm:flex-row w-full" id={'store-info'}>
            {/* Store Hours Section */}
            <div className="flex-1 p-5 flex flex-col justify-center items-center text-center">
                <FaClock className="text-3xl text-red-500 my-3"/>
                <h2 className="font-bold text-xl mb-3">Store Hours</h2>
                <ul className={'text-left'}>
                    <li className="mb-4">Monday: 9:30am - 6pm</li>
                    <li className="mb-4">Tuesday: 9:30am - 5pm</li>
                    <li className="mb-4">Wednesday: 9:30am - 7pm</li>
                    <li className="mb-4">Thursday: 9:30am - 5pm</li>
                    <li className="mb-4">Friday: 9:30am - 5pm</li>
                    <li className="mb-4">Saturday: 9:30am - 4pm</li>
                    <li>Sunday: Closed</li>
                </ul>
            </div>
            {/* Location Section */}
            <div className="flex-1 p-5 flex flex-col justify-center items-center text-center">
                    <FaMapMarkerAlt className="text-3xl text-green-500 my-3"/>
                    <h2 className="font-bold text-xl mb-3">Location</h2>
                    <div className="overflow-hidden rounded-lg h-64 w-full items-center justify-center text-center">
                        <MapContainer center={position} zoom={16} scrollWheelZoom={false}
                                      className={' z-0 max-w-xl h-full rounded-lg mx-auto'}>
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            />
                            <Marker position={position} icon={customMarkerIcon}>
                                <Popup>
                                    888 Willis Ave, Albertson, NY 11507 {/* Change this text to your store name or info */}
                                </Popup>
                            </Marker>
                        </MapContainer>
                    </div>
                        <p className={'text-black'}><a href="https://maps.app.goo.gl/Q7c7u27aYodsmJqJ9" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">888
                            Willis Ave, Albertson, NY 11507</a></p>
            </div>
        </div>
)
;
}

export default StoreInfo;
