import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa'; // Import icons for the menu button

function Navbar() {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isOpen, setIsOpen] = useState(false); // State to manage toggle menu visibility

    useEffect(() => {
        const handleScroll = () => {
            const heroSection = document.getElementById('hero'); // Get the hero section element
            const heroHeight = heroSection.offsetHeight; // Height of the hero section

            if (window.scrollY > heroHeight) {
                setIsScrolled(true);
                setIsOpen(false)
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <nav className={`fixed w-full z-50 transition duration-300 ease-in-out ${isScrolled ? 'bg-black bg-opacity-90' : 'bg-black bg-opacity-30'}`}>
            <div className="max-w-6xl mx-auto px-4">
                <div className="flex justify-between items-center">
                    <a href="#" className="text-xl font-semibold text-white py-5">Vision World Albertson</a>
                    <button onClick={() => setIsOpen(!isOpen)} className="text-white text-3xl md:hidden">
                        {isOpen ? <FaTimes /> : <FaBars />}
                    </button>
                    <div className={`absolute inset-x-0 top-full transition transform origin-top-right md:hidden ${isOpen ? 'scale-y-100' : 'scale-y-0'} ${isScrolled ? 'bg-black bg-opacity-90' : 'bg-black bg-opacity-30'}`} style={{transition: 'transform 0.3s ease'}}>
                        <div className="px-5 pt-4 flex flex-col items-center justify-between">
                            <a href="#" className="block px-3 py-2 rounded-md text-base font-medium text-white my-1">Home</a>
                            <a href="#store-info" className="block px-3 py-2 rounded-md text-base font-medium text-white my-1">Visit Us</a>
                            <a href="#contact-us" className="block px-3 py-2 rounded-md text-base font-medium text-white my-1">Contact</a>
                        </div>
                    </div>
                    <div className={`hidden md:flex space-x-4`}>
                        <a href="#" className="text-white py-5">Home</a>
                        <a href="#store-info" className="text-white py-5">Visit Us</a>
                        <a href="#contact-us" className="text-white py-5">Contact</a>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
