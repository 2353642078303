import React, { useState } from 'react';
import MarketingSignup from "./MarketingSignup";

function ContactUs() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));

    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent form from reloading the page

        try {


            // Define the API URL and ClientId using environment variables
            const apiUrl = `${process.env.REACT_APP_APIUrl}/ContactUsForm`;
            const clientId = process.env.REACT_APP_ClientId;

            // Make a POST request to the API
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Set the appropriate headers for JSON
                    'ClientId': clientId, // Add the ClientId to the headers
                },
                body: JSON.stringify(formData), // Send the form data as JSON
            });

            // Check if the response is successful
            if (response.ok) {
                setFormData({ name: '', email: '', message: '' }); // Reset form data
                alert("Message sent successfully");
            } else {
                console.error('Form submission failed', response.statusText);
            }
        } catch (error) {
            console.error('Error occurred during form submission:', error);
        }
    };

    return (
        <div className="container mx-auto px-4 py-10 flex flex-col md:flex-row" id={'contact-us'}>
            {/* Form Section */}
            <div className="flex-1 px-5 py-4 border-b sm:border-b-0 md:border-r border-gray-300">
                <h2 className="text-2xl font-bold mb-6 text-center">Online Inquiries</h2>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                        <input
                            type="text"
                            name="name"
                            id="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message</label>
                        <textarea
                            name="message"
                            id="message"
                            value={formData.message}
                            onChange={handleChange}
                            rows="4"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            required
                        ></textarea>
                    </div>
                    <button
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Send Message
                    </button>
                </form>
            </div>

            {/* Contact Details Section */}
            <div className="flex-1 px-5 py-4 items-center justify-center text-center">
                <h2 className="text-2xl font-bold mb-6">Contact Details</h2>
                <p className="text-sm text-gray-700 mb-2"><strong>Phone:</strong><a href={"tel:+15167476363"} className="text-blue-500 hover:text-blue-700"> +1 (516) 747-6363</a></p>
                <p className="text-sm text-gray-700 mb-2"><strong>Email:</strong><a href={"mailto:visionworldalbertsonny@gmail.com"} className="text-blue-500 hover:text-blue-700"> visionworldalbertsonny@gmail.com </a></p>
                <p className="text-sm text-gray-700 mb-2"><strong>Address:</strong> <a href="https://maps.app.goo.gl/Q7c7u27aYodsmJqJ9" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">888 Willis Ave, Albertson, NY 11507 </a></p>
                <MarketingSignup/>
            </div>
        </div>
    );
}

export default ContactUs;
