import React, { useState } from 'react';

function MarketingSignup() {
    const [email, setEmail] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        const requestBody = {
            email: email,
            business: "Vision World ALbertson"
        };

        try {
            const response = await fetch('https://api.ax2tech.com/emailsignup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });


            if (response.ok) {
                const data = await response.json();
                alert(`Thank you for subscribing, ${email}!`);
                setEmail(''); // Reset the email input after submission
            } else {
                alert('Failed to subscribe. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred. Please try again.');
        }
    };

    return (
        <div className="bg-white p-6 rounded-lg max-w-lg mx-auto my-10">
            <h2 className="text-2xl font-bold text-center mb-4">Join Our Newsletter</h2>
            <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                />
                <button
                    type="submit"
                    className="bg-blue-500 text-white font-bold py-2 rounded-lg hover:bg-blue-600 transition duration-150"
                >
                    Subscribe
                </button>
            </form>
            <p className="text-sm text-gray-600 mt-4 text-center">
                Subscribe to get special offers, free giveaways, and once-in-a-lifetime deals.
            </p>
        </div>
    );
}

export default MarketingSignup;
