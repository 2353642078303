import React from 'react';

function ImageShowcase() {
    const images = [
        './photos/p1.jpeg',  // Replace these with actual paths to your images
        './photos/p2.jpeg',
        './photos/p3.jpeg',
        './photos/p4.jpeg'
    ];

    return (
        <div className="container mx-auto px-4 my-12">
            <h2 className="text-2xl font-bold text-center mb-8">Product Showcase</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {images.map((image, index) => (
                    <div key={index} className="overflow-hidden rounded-lg shadow-lg">
                        <img src={image} alt={`Showcase ${index}`} className="w-full h-auto transform hover:scale-110 transition duration-500 ease-in-out"/>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ImageShowcase;
