import React from 'react';

function HeroSection() {
    return (
        <div className="relative w-full h-screen bg-cover bg-center " style={{backgroundImage: `url(./photos/cover.jpeg)`}} id={'hero'}>
            <div className="absolute inset-0 bg-black bg-opacity-20"></div>
            <div className="flex flex-col justify-center items-center h-full relative z-10 text-center">
                <h1 className="text-white text-5xl font-bold">Vision World of Albertson</h1>
                <p className="text-white text-xl mt-2">Find your perfect pair of glasses with us!</p>
            </div>
        </div>
    );
}

export default HeroSection;