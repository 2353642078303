import React, { useState, useEffect, useRef } from 'react';

function TopBrands() {
    const brands = [
        { name: 'Gucci', logo: './brands/gucci.svg', link: 'https://www.gucci.com' },
        { name: 'Prada', logo: './brands/prada.svg', link: 'https://www.prada.com' },
        { name: 'Ray-Ban', logo: './brands/ray-ban.svg', link: 'https://www.ray-ban.com'},
        { name: 'Oakley', logo: './brands/oakley.svg', link: 'https://www.oakley.com' },
        { name: 'Tom Ford', logo: './brands/tomford.svg', link: 'https://www.tomford.com' }
    ];

    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect(); // Optional: disconnect observer if you want animation only once
                }
            },
            {
                rootMargin: '0px',
                threshold: 0.5 // Adjust threshold to control when the animation should start
            }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => observer.disconnect(); // Cleanup the observer
    }, []);

    return (
        <div ref={ref} className="bg-white py-10">
            <div className="container mx-auto px-4">
                <h2 className="text-2xl font-bold text-center mb-6">Top Brands</h2>
                <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-52">
                    {brands.map((brand, index) => (
                        <a key={index} href={brand.link} target="_blank" rel="noopener noreferrer"
                           className={`hover:scale-105 transition transform duration-1000 ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'}`}>
                            <img src={brand.logo} alt={`${brand.name} Logo`} className="h-36 transition-opacity duration-1000 ease-in" />
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default TopBrands;
