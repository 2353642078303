import React from 'react';
import { FaGlasses, FaEye, FaStethoscope } from 'react-icons/fa'; // Import specific icons

function FeaturesRow() {
    const features = [
        {
            ico: <FaGlasses className="text-3xl text-blue-500" />,
            header: "Shop Glasses",
            description: "Explore our extensive range of stylish and functional eyewear. Find frames and lenses that fit your lifestyle and fashion sense perfectly."
        },
        {
            ico: <FaEye className="text-3xl text-pink-500" />,
            header: "Contacts",
            description: "Discover the perfect contact lenses with a personalized fitting session at our store. Comfort and clarity are just a visit away."
        },
        {
            ico: <FaStethoscope className="text-3xl text-teal-500" />,
            header: "Eye Exams",
            description: "Book an eye exam with our expert optometrists to ensure your vision health. We offer comprehensive eye care services Monday to Saturday."
        }
    ];

    return (
        <div className="flex flex-col md:flex-row justify-around items-center my-10">
            {features.map((feature, index) => (
                <div key={index} className="flex flex-col items-center px-5 text-center justify-center my-4 md:my-0">
                    <div className="mb-2">{feature.ico}</div>
                    <h3 className="text-lg font-semibold">{feature.header}</h3>
                    <p className="text-sm text-gray-600">{feature.description}</p>
                </div>
            ))}
        </div>
    );
}

export default FeaturesRow;
