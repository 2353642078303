import logo from './logo.svg';
import './App.css';
import HeroSection from "./comps/HeroSection";
import Navbar from "./comps/Navbar";
import StoreInfo from "./comps/StoreInfo";
import Footer from "./comps/Footer";
import FeaturedRow from "./comps/FeaturedRow";
import MarketingSignup from "./comps/MarketingSignup";
import ImageShowcase from "./comps/ImageShowcase";
import ImageCarousel from "./comps/ImageCarousel";
import ContactUs from "./comps/ContactUs";
import TopBrands from "./comps/TopBrands";

  function App() {
    return (
        <div>
          <Navbar />
          <HeroSection />
            <FeaturedRow/>
            <StoreInfo/>
            <ImageShowcase/>
            <TopBrands/>
            <ContactUs/>
            <Footer/>
          {/* You can add more sections here */}
        </div>
    );}

export default App;
