import React from 'react';

function Footer() {
    return (
        <footer className="bg-gray-800 text-white mt-10 py-5">
            <div className="container mx-auto px-4 text-center">
                <p className="text-sm">© {new Date().getFullYear()} Vision World Albertson. All rights reserved.</p>
                <p className="text-sm mt-2">
                    Powered by <a href="https://ax2tech.com" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-600">Ax2 Technologies</a>
                </p>
            </div>
        </footer>
    );
}

export default Footer;
